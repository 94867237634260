import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Privacy from "./routes/privacy";

import patlaxLogo from "./img/Patlax.png";
import "./App.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
]);

export default function App() {
  return (
    <div id="app">
      <header>
        <img src={patlaxLogo} alt="Patlax logo" />
      </header>
      <RouterProvider router={router} />
    </div>
  );
}
