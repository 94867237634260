import releasingLogo from "../img/releasing_soon.png";
import "./root.css";

export default function Root() {
  return (
    <main id="root">
      <img src={releasingLogo} alt="Releasing soon logo" />
    </main>
  );
}
